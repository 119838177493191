<script setup lang="ts">
import LayoutGlobal from './Global.vue';
import LayoutHeader from './Header.vue';
import LayoutSidebar from './Sidebar.vue';
import LayoutContent from './Content.vue';
import SupportModal from './../../../partners-support/ui/Modal.vue';
import { UiAlertService } from '~/src/shared/ui';
import { notificationBus } from '~/src/features/app/infrastructure/bus';

const alertServiceTarget = ref<InstanceType<typeof UiAlertService> | null>(
	null,
);

notificationBus.useOnNotificationError((errorMessage) => {
	alertServiceTarget.value?.addError(errorMessage);
});
</script>

<template>
	<LayoutGlobal>
		<div class="flex w-full justify-center">
			<UiAlertService
				ref="alertServiceTarget"
				class="fixed top-[10px] !min-w-[30%] !max-w-[80%] w-max z-[100000000000000] bg-gray-800"
			/>
		</div>
		<LayoutHeader />

		<LayoutSidebar />
		<SupportModal />
		<LayoutContent>
			<slot />
		</LayoutContent>
	</LayoutGlobal>
</template>
