<script setup lang="ts">
import {
	useIsAdminLayout,
	useIsPartnerLayout,
	useIsTraderLayout,
} from '../../core/application';
import { useIsSidebarOpen } from '../../infrastructure/store';
import { usePartners, useAdmin, useTrader } from '../../hooks';
import LayoutSidebarTemplate from './SidebarTemplate.vue';
import LayoutNav from './Nav.vue';

const { $viewport } = useNuxtApp();

const isPartnerLayout = useIsPartnerLayout();
const isAdminLayout = useIsAdminLayout();
const isTraderLayout = useIsTraderLayout();
const isSidebarOpen = useIsSidebarOpen();

const trader = useTrader();
const admin = useAdmin();
const partners = usePartners();

const list = computed(() => {
	if (isPartnerLayout.value) {
		return partners.value.list;
	}
	else if (isAdminLayout.value) {
		return admin.value.list;
	}
	else if (isTraderLayout.value) {
		return trader.value.list;
	}
	return '';
});

const additional = computed(() => {
	if (isTraderLayout.value) {
		return trader.value.additional;
	}

	return [];
});

const sub = computed(() => {
	if (isAdminLayout.value) {
		return {
			title: 'Партнерка',
			list: admin.value.sub,
		};
	}

	return undefined;
});

const isShort = computed(() => {
	if (isTraderLayout.value) {
		return $viewport.match('sm') ? !isSidebarOpen.value : true;
	}
	else {
		return !isSidebarOpen.value;
	}
});
</script>

<template>
	<LayoutSidebarTemplate>
		<LayoutNav
			:list="list"
			:sub="sub"
			:additional="additional"
			:short="isShort"
		/>
	</LayoutSidebarTemplate>
</template>
