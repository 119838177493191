import { apiMethods } from '../../infrastructure/api';
import { savePartnersInvoices } from '../../infrastructure/store';

export const useGetPartnersInvoices = () => {
	const getInvoices = apiMethods.useGetInvoices();

	return async () => {
		const result = await getInvoices();

		if (result.isRight()) {
			savePartnersInvoices(result.value);
		}
	};
};
