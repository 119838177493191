<script setup lang="ts">
import { PAGE_DRAWERS } from '../../core/constants';
import PageDrawersItem from '../Item.vue';
import {
	WithdrawalNotVerifiedCard,
	WithdrawalSection,
} from '@/src/features/withdrawal';
import { useIsUserFullVerified } from '@/src/features/user';

const isFullVerified = useIsUserFullVerified();
</script>

<template>
	<PageDrawersItem
		title="Вывод"
		:name="PAGE_DRAWERS.withdrawal"
	>
		<WithdrawalSection v-if="isFullVerified" />

		<WithdrawalNotVerifiedCard v-else />
	</PageDrawersItem>
</template>
