<script setup lang="ts" generic="T extends string">
import { BaseIcon } from '@legacy-studio/library';
import type { ComputedRef } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { getDrawerIndex, hasPrevDrawer, isDrawerOpen } from '../core/utils';

const props = withDefaults(
	defineProps<{
		title: string;
		fullscreen?: boolean;
		name: T;
		useDrawerNames: () => ComputedRef<Array<T>>;
		direction?: 'left' | 'right';
	}>(),
	{
		direction: 'left',
		fullscreen: false,
	},
);

const emit = defineEmits<{
	close: [];
	back: [];
}>();

const drawersNames = props.useDrawerNames();

const close = () => {
	emit('close');
};

const back = () => {
	emit('back');
};

const isOpen = computed(() => isDrawerOpen(props.name, drawersNames.value));

const withBack = computed(() => hasPrevDrawer(props.name, drawersNames.value));

const index = computed(() => getDrawerIndex(props.name, drawersNames.value));
const sidebarRef = ref<HTMLElement | null>(null);
onClickOutside(sidebarRef, (event) => {
	const isNestedClick = drawersNames.value.some((name) => {
		const drawerElement = document.querySelector(`[data-drawer-name="${name}"]`);
		return drawerElement?.contains(event.target as Node);
	});

	if (isOpen.value && !isNestedClick) {
		close();
	}
});
</script>

<template>
	<Transition
		:name="`widget-${direction}`"
	>
		<div
			v-if="isOpen"
			ref="sidebarRef"
			:data-drawer-name="props.name"
			class="md:flex-none overflow-y-auto bg-gray-800 flex flex-col gap-4 p-4 overflow-x-hidden"
			:class="{
				'md:w-[320px]': !fullscreen,
				'left-0 md:left-[60px]':
					direction === 'left' || (fullscreen && direction === 'right'),
				'right-0':
					direction === 'right' || (fullscreen && direction === 'left'),
				'border-l border-gray-600 md:border-l-0 md:border-r':
					direction === 'left',
				'border-r border-gray-600 md:border-r-0 md:border-l':
					direction === 'right',
			}"
			:style="{
				zIndex: Math.max(index, 0) + 60,
			}"
		>
			<header class="flex justify-between">
				<button
					v-if="withBack"
					type="button"
					@click="back"
				>
					<BaseIcon
						name="base--fill--arrow-narrow-left"
						class="text-white text-[18px]"
					/>
				</button>

				<span class="text-base font-semibold text-white">
					{{ title }}
				</span>

				<button
					type="button"
					@click="close"
				>
					<BaseIcon
						name="base--fill--x"
						class="text-gray-400 text-[18px]"
					/>
				</button>
			</header>

			<div
				class="flex flex-col gap-8 h-full"
			>
				<slot />
			</div>
		</div>
	</Transition>
</template>

<style>
/* Общие переходы */
.widget-enter-active,
.widget-leave-active {
  transition: transform 0.7s cubic-bezier(0.25, 1, 0.5, 1), opacity 0.7s ease; /* Более плавный и контролируемый переход */
}

/* Анимация для direction: 'left' */
.widget-enter-from-left {
  transform: translateX(-120%); /* Более сильный сдвиг для плавного эффекта */
  opacity: 0;
}

.widget-enter-to-left {
  transform: translateX(0); /* Плавное возвращение */
  opacity: 1;
}

.widget-leave-from-left {
  transform: translateX(0);
  opacity: 1;
}

.widget-leave-to-left {
  transform: translateX(-120%); /* Уход за пределы */
  opacity: 0;
}

/* Анимация для direction: 'right' */
.widget-enter-from-right {
  transform: translateX(120%);
  opacity: 0;
}

.widget-enter-to-right {
  transform: translateX(0);
  opacity: 1;
}

.widget-leave-from-right {
  transform: translateX(0);
  opacity: 1;
}

.widget-leave-to-right {
  transform: translateX(120%);
  opacity: 0;
}

</style>
