<script setup lang="ts">
import { PAGE_DRAWERS } from '../../core/constants';
import PageDrawersItem from '../Item.vue';
import {
	OpenedDealsSectionDetail,
	useOpenedDealsSectionName,
} from '@/src/features/terminal-deals';

const openedDealsSectionName = useOpenedDealsSectionName();
</script>

<template>
	<PageDrawersItem
		:title="openedDealsSectionName"
		:name="PAGE_DRAWERS.detailDeal"
	>
		<OpenedDealsSectionDetail />
	</PageDrawersItem>
</template>
