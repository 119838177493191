<script setup lang="ts">
import { BaseIcon, BaseDropdown, BaseBadge, BindPopover } from '@legacy-studio/library';
import { onClickOutside, useToggle } from '@vueuse/core';
import dayjs from 'dayjs';
import {
	toggleSidebar,
	useIsAdminLayout,
	useIsPartnerLayout,
	useIsTraderLayout,
} from '../../core/application';
import { useIsSidebarOpen } from '../../infrastructure/store';
import ScopeSwither from './ScopeSwither.vue';
import { InvoicesSelect } from '@/src/features/invoices';
import { InvoicesPartners } from '@/src/features/partners-invoices';
import { useLogout } from '@/src/features/auth';
import {
	getBgColor,
	useRootUserUrl,
	useUserRequired,
} from '@/src/features/user';
import { openProfileModal } from '@/src/features/profile';
import {
	UiBadgeTooltip,
	UiCopyBadge,
	UiDarkBBButton,
	UiLogo,
} from '@/src/shared/ui';
import { TariffsBadge } from '~/src/features/tariffs';
import { PAGE_DRAWERS, pageDrawers } from '~/src/features/page-drawers';

const isSidebarOpen = useIsSidebarOpen();
const isPartnerLayout = useIsPartnerLayout();
const isAdminLayout = useIsAdminLayout();
const isTraderLayout = useIsTraderLayout();
const user = useUserRequired();
const rootUrl = useRootUserUrl();

const [isOpen, toggle] = useToggle();

const logout = useLogout();

const openProfile = () => {
	openProfileModal();
	toggle(false);
};

const logoTitle = computed(() => {
	if (isPartnerLayout.value) {
		return 'BBAF';
	}
	else if (isTraderLayout.value) {
		return 'BBroker';
	}
	else if (isAdminLayout.value) {
		return 'BBAD';
	}
	return '';
});

const openTariffModal = () => {
	// modalStore.open("tariff"); TODO
	toggle(false);
};

const target = ref();
onClickOutside(target, () => {
	toggle(false);
});

const registrationDateText = computed(() => {
	return user.value.registrationDate
		? dayjs(user.value.registrationDate).utc().format('DD.MM.YYYY')
		: '';
});

const refCode = computed(() => user.value.referralCode);

const openTopUp = () => {
	pageDrawers.open(PAGE_DRAWERS.topUp);
};
const closeTopUp = () => {
	pageDrawers.close(PAGE_DRAWERS.topUp);
};
</script>

<template>
	<header
		v-if="user"
		class="fixed left-0 right-0 top-0 flex h-16 items-center justify-between border-b border-gray-600 bg-gray-800 pl-3 pr-4 z-50"
	>
		<div class="flex gap-[18px]">
			<button
				v-if="isTraderLayout ? $viewport.match('sm') : true"
				class="flex items-center justify-center rounded-lg p-1 text-gray-400 hover:bg-gray-700 hover:text-white"
				@click="toggleSidebar"
			>
				<!--				<UiIcon -->
				<!--					:name="isSidebarOpen ? 'fill&#45;&#45;x' : 'fill&#45;&#45;menu-alt-2'" -->
				<!--					class="text-[24px] text-gray-400" -->
				<!--				/> -->
				<BaseIcon
					:name="isSidebarOpen ? 'base--fill--x' : 'base--fill--menu-alt-2'"
					class="text-[24px]"
				/>
			</button>

			<NuxtLink
				v-if="isTraderLayout ? !$viewport.match('sm') : true"
				:to="rootUrl"
				class="flex items-center gap-2"
			>
				<UiLogo
					v-if="$viewport.isGreaterOrEquals('md') || isTraderLayout"
					size="sm"
				/>

				<span class="hidden text-lg font-semibold text-white xl:inline">
					{{ logoTitle }}
				</span>
			</NuxtLink>
		</div>

		<div class="flex items-center gap-3">
			<div v-if="!isAdminLayout">
				<InvoicesSelect v-if="isTraderLayout" />
				<InvoicesPartners v-if="isPartnerLayout" />
			</div>

			<UiDarkBBButton
				v-if="$viewport.match('sm')"
				size="xs"
				icon-only
				icon-style="base--fill--credit-card"
				class="rounded-lg h-[32px] w-[32px]"
			/>

			<UiDarkBBButton
				v-else-if="isTraderLayout"
				size="xs"
				left-icon-style="base--fill--credit-card"
				button-text="Пополнить"
				@click="openTopUp"
			/>

			<div
				ref="target"
				class="flex gap-3"
			>
				<ScopeSwither v-if="isAdminLayout" />

				<button
					class="relative"
					@click="toggle()"
				>
					<img
						v-if="user.photo"
						:key="user.photo"
						:src="user.photo"
						alt="avatar"
						class="h-8 w-8 rounded-lg"
					>

					<div
						v-else
						:class="getBgColor(user)"
						class="h-8 w-8 rounded-lg flex items-center justify-center text-white text-sm font-normal"
					>
						{{ user.login[0] }}
					</div>
					<BindPopover
						v-if="isOpen"
						ref="target"
						:top="32"
						:right="0"
					>
						<BaseDropdown
							class="absolute right-0 z-10 py-1 top-[calc(100%+3px)] flex flex-col cursor-default w-[224px] ring-1 ring-gray-600"
							@click.stop
						>
							<div class="p-3 flex flex-col gap-3">
								<div class="flex flex-col text-left">
									<span class="text-sm font-semibold text-white">
										{{ user.login }}
									</span>

									<span class="text-sm font-normal text-gray-500">
										{{ user.email }}
									</span>
								</div>

								<UiCopyBadge
									v-if="!isPartnerLayout"
									:value="user.id"
									label="ID:"
								/>

								<BaseBadge
									v-if="!isTraderLayout && registrationDateText"
									color="gray"
								>
									<span class="text-white"> ЗАРЕГИСТРИРОВАН: </span>
									<span class="text-gray-500"> {{ registrationDateText }} </span>
								</BaseBadge>

								<template v-if="isPartnerLayout && refCode">
									<UiBadgeTooltip
										title="REFCODE:"
										:value="refCode"
									/>
								</template>

								<template v-else-if="isTraderLayout">
									<div class="flex gap-2">
										<TariffsBadge :type="user.accountType" />

										<button
											type="button"
											@click="openTariffModal"
										>
											<BaseBadge
												color="gray"
											>
												<BaseIcon
													name="base--fill--cog"
													class="text-gray-300 text-[14px]"
												/>
											</BaseBadge>
										</button>
									</div>
								</template>

								<BaseBadge
									v-else-if="isAdminLayout"
									color="gray"
								>
									<span class="text-white"> АДМИНИСТРАТОР </span>
								</BaseBadge>
							</div>

							<div class="py-1 border-t border-gray-600">
								<button
									class="text-left px-4 py-2 text-sm font-normal text-gray-400 w-full flex whitespace-nowrap"
									type="button"
									@click="openProfile"
								>
									<template v-if="isTraderLayout">
										Профиль
									</template>

									<template v-else>
										Настройка профиля
									</template>
								</button>
							</div>

							<div class="py-1 border-t border-gray-600">
								<button
									type="button"
									class="text-left px-4 py-2 text-sm font-normal text-gray-400 w-full flex"
									@click="logout"
								>
									Выход
								</button>
							</div>
						</BaseDropdown>
					</BindPopover>
				</button>
			</div>
		</div>
	</header>
</template>
