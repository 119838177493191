<script setup lang="ts">
import { useIsSidebarOpen } from '../../infrastructure/store';
import { useIsTraderLayout } from '../../core/application';
import { PageDrawersList } from '~/src/features/page-drawers';

const isTraderLayout = useIsTraderLayout();
const isSidebarOpen = useIsSidebarOpen();
</script>

<template>
	<div
		class="flex min-h-screen w-screen bg-gray-800 pt-16 transition-all duration-75"
		:class="{
			'xl:pl-[250px]': !isTraderLayout && isSidebarOpen,
			'xl:pl-[60px]': !isTraderLayout && !isSidebarOpen,
			'md:pl-[61px]': isTraderLayout,
		}"
	>
		<PageDrawersList />
		<slot />
	</div>
</template>
