<script setup lang="ts">
import { useToggle, onClickOutside } from '@vueuse/core';
import { BaseIcon } from '@legacy-studio/library';
import { useScopes } from '../../hooks';

const [isOpen, toggle] = useToggle();

const target = ref();
onClickOutside(target, () => {
	toggle(false);
});

const scopes = useScopes();
</script>

<template>
	<div
		ref="target"
		class="relative"
	>
		<button
			class="flex items-center justify-center p-1 text-gray-400 hocus:ring-[3px] hocus:ring-gray-600 hocus:bg-gray-700 hocus:text-white rounded-lg hocus:outline-none"
			@click="toggle()"
		>
			<BaseIcon
				name="base--fill--view-grid"
				class="text-[24px]"
			/>
		</button>

		<div
			v-if="isOpen"
			class="absolute right-0 top-[calc(100%+30px)] ring-1 ring-inset ring-gray-600 rounded-lg bg-gray-700 flex overflow-hidden"
		>
			<button
				v-for="scope in scopes"
				:key="scope.title"
				type="button"
				class="flex flex-col gap-3 items-center py-5 w-[163px]"
				:class="{
					'bg-gray-600': scope.active,
					'hover:bg-gray-600': !scope.active,
				}"
				:disabled="scope.active"
				@click="scope.callback()"
			>
				<BaseIcon
					:name="scope.icon"
					class="text-[32px] text-gray-400"
				/>

				<span
					class="leading-none text-lg font-normal"
					:class="{
						'text-white': scope.active,
						'text-gray-300': !scope.active,
					}"
				>
					{{ scope.title }}
				</span>
			</button>
		</div>
	</div>
</template>
