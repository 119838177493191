<script setup lang="ts">
import { formatAmount } from '@legacy-studio/library';
import { useGetPartnersInvoices } from '../core/application';
import { usePartnersInvoice } from '../infrastructure/store';

const getInvoices = useGetPartnersInvoices();

const invoice = usePartnersInvoice();

getInvoices();
</script>

<template>
	<div class="flex gap-4 items-center text-white">
		<div class="flex flex-col items-end">
			<span class="text-gray-500 text-xs font-normal">Баланс:</span>
			<span class="text-base font-bold">{{
				formatAmount(invoice.balance)
			}}</span>
		</div>
		<div class="flex flex-col items-end">
			<span class="text-gray-500 text-xs font-normal">Весь доход:</span>
			<span class="text-base font-bold">{{
				formatAmount(invoice.income)
			}}</span>
		</div>
	</div>
</template>
