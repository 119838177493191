<script setup lang="ts">
import {
	BaseModal,
	BaseModalCard,
	BaseModalCardHeader,
	BaseModalCardContent,
} from '@legacy-studio/library';
import { closeSupportModal } from '../core/application';
import { useIsSupportOpen } from '../infrastructure/store';
import SupportForm from './Form.vue';

const isOpen = useIsSupportOpen();

const localModel = computed({
	get() {
		return isOpen.value;
	},

	set() {
		closeSupportModal();
	},
});
</script>

<template>
	<BaseModal
		v-model="localModel"
		size="md"
	>
		<BaseModalCard
			class="max-w-[480px] max-h-[646px]"
			hide-close
		>
			<BaseModalCardHeader with-close>
				<p class="text-2xl font-bold text-white">
					Техподдержка
				</p>
			</BaseModalCardHeader>

			<BaseModalCardContent>
				<section class="flex flex-col items-center gap-4">
					<p class="text-center text-base font-normal text-gray-400">
						Свяжитесь с нами, если у вас есть вопросы или пожелания по работе платформы.
					</p>
				</section>
				<SupportForm is-full-width />
			</BaseModalCardContent>
		</BaseModalCard>
	</BaseModal>
</template>
