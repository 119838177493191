export const isDrawerOpen = <T extends string>(name: T, names: Array<T>) => {
	return names.includes(name);
};

export const hasPrevDrawer = <T extends string>(name: T, names: Array<T>) => {
	const idx = names.indexOf(name);
	return idx > 0;
};

export const getDrawerIndex = <T extends string>(name: T, names: Array<T>) => {
	return names.indexOf(name);
};
