<script setup lang="ts">
import { BaseIcon, BaseLink } from '@legacy-studio/library';
import { useToggle } from '@vueuse/core';
import type { ListItem } from '../../types';
// eslint-disable-next-line import/no-self-import
import LayoutNav from './Nav.vue';
import { pageDrawers } from '~/src/features/page-drawers';
import { useDrawerStore } from '~/src/features/page-drawers/core/store';

defineProps<{
	list: ListItem[];
	additional?: ListItem[];
	sub?: {
		title: string;
		list: ListItem[];
	};
	short: boolean;
}>();

const router = useRouter();
const activeRoute = computed(() => router.currentRoute.value.path);

const [isOpen, toggle] = useToggle();

const drawerStore = useDrawerStore();
const { activeItem } = storeToRefs(drawerStore);
const handleItemClick = async (link: string | null) => {
	if (activeItem.value === link) {
		drawerStore.resetActiveItem();
		if (link) {
			const parentPath = link.split('/').slice(0, -1).join('/') || '/';
			await router.push(parentPath);
			pageDrawers.close();
		}
	}
	else {
		drawerStore.setActiveItem(link);
		if (link) {
			await router.push(link);
		}
	}
};
</script>

<template>
	<nav>
		<ul
			class="flex flex-col px-2.5 py-4 gap-3"
			:class="{
				'w-full xl:w-[250px]': !short,
				'w-[60px]': short,
			}"
		>
			<li
				v-for="item in list"
				:key="item.link"
			>
				<BaseLink
					v-if="item.link"
					:to="item.link"
					class="flex gap-3 rounded-lg p-2"
					:class="{
						'bg-gray-700 ring-1 ring-gray-600': activeRoute === item.link,
					}"
					@click="handleItemClick(item.link)"
				>
					<BaseIcon
						:name="item.icon"
						class="text-[24px] text-gray-400"
						:class="{
							'text-primary-300': activeRoute === item.link,
						}"
					/>

					<span
						v-if="!short"
						class="whitespace-nowrap text-base font-medium text-gray-400"
						:class="{
							'text-white': activeRoute === item.link,
						}"
					>
						{{ $t(item.title) }}
					</span>
				</BaseLink>
				<button
					v-else
					class="flex gap-3 rounded-lg px-2"
					:class="{
						'py-1.5': !short,
						'p-2': short,
					}"
					@click="item.modal"
				>
					<BaseIcon
						:name="item.icon"
						class="text-[24px] text-gray-400"
					/>
					<span
						v-if="!short"
						class="whitespace-nowrap text-base font-medium text-gray-400"
					>
						{{ $t(item.title) }}
					</span>
				</button>
			</li>
		</ul>
	</nav>
	<div
		v-if="sub"
		class="px-2.5 py-4"
	>
		<div
			class="flex flex-col gap-4 rounded-lg bg-gray-700 px-2 py-2 ring-1 ring-gray-600"
		>
			<button
				class="flex"
				:class="{
					'gap-3': !short,
					'gap-[14px]': short,
				}"
				@click="toggle()"
			>
				<BaseIcon
					name="base--fill--user-group"
					class="text-[24px] text-gray-400"
					:class="{
						'text-primary-300': isOpen,
					}"
				/>

				<span
					v-show="!short"
					class="text-white"
				>
					{{ sub.title }}
				</span>

				<BaseIcon
					v-show="!short"
					name="base--fill--chevron-down"
					class="ml-auto text-[24px] text-gray-400"
					:class="{
						'rotate-180 text-white': isOpen,
					}"
				/>
			</button>

			<ul
				v-show="isOpen"
				class="flex flex-col gap-[14px]"
			>
				<li
					v-for="item in sub.list"
					:key="item.link"
				>
					<BaseLink
						:to="item.link"
						class="flex gap-2.5 rounded-lg py-1.5"
					>
						<BaseIcon
							:name="item.icon"
							class="text-[24px] text-gray-400"
							:class="{
								'text-white': activeRoute === item.link,
							}"
						/>

						<span
							v-show="!short"
							class="text-base font-medium text-gray-400"
							:class="{
								'text-white': activeRoute === item.link,
							}"
						>
							{{ $t(item.title) }}
						</span>
					</BaseLink>
				</li>
			</ul>
		</div>
	</div>

	<div
		v-if="additional"
		class="border-t border-b border-gray-600"
	>
		<LayoutNav
			:list="additional"
			:short="short"
		/>
	</div>
</template>
