<script setup lang="ts">
import { PAGE_DRAWERS } from '../../core/constants';
import PageDrawersItem from '../Item.vue';
import { useSelectedTournament } from '~/src/features/tournaments/infrastructure/store';
import { TournamentItem } from '~/src/features/tournaments';

const selectTournamentName = computed(() => {
	const tournament = useSelectedTournament();
	return tournament ? tournament.name : '';
});
</script>

<template>
	<PageDrawersItem
		:title="selectTournamentName"
		:name="PAGE_DRAWERS.tournament"
	>
		<TournamentItem />
	</PageDrawersItem>
</template>
