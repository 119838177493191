<script setup lang="ts">
import { PAGE_DRAWERS } from '../../core/constants';
import PageDrawersItem from '../Item.vue';
import { BonusesItem, useBonusesSelected } from '~/src/features/bonuses';

const bonusesSelected = useBonusesSelected();
</script>

<template>
	<PageDrawersItem
		:title="bonusesSelected?.title || ''"
		:name="PAGE_DRAWERS.bonusesDetail"
	>
		<BonusesItem />
	</PageDrawersItem>
</template>
