import type { Invoice } from '../../types';
import { useApi } from '@/src/services/network';
import type { Response } from '~/src/shared/lib';

export type InvoicesDTO = Response<{
	id: string;
	user: string;
	balance: number;
	income: number;
}>;

export const toDomain = (data: InvoicesDTO): Invoice => ({
	id: data.result.id,
	balance: data.result.balance,
	income: data.result.income,
});

export const useGetInvoices = () => {
	const api = useApi();

	return async () =>
		(await api<InvoicesDTO>('/Invoices/getPartner/')).mapRight(toDomain);
};
