<script setup lang="ts">
import { BaseAccordion, BaseIcon } from '@legacy-studio/library';
import { PAGE_DRAWERS } from '../../core/constants';
import PageDrawersItem from '../Item.vue';

const sections = [
	{
		title: 'Быстрый старт',
		code: 'quick',
		items: [
			{
				title: 'Регистрация',
				content: `
          Присоединяйтесь к нашей платформе, заполнив быструю форму регистрации на главной странице сайта. Подтвердите свой адрес электронной почты, перейдя по ссылке в письме. Регистрация на нашей платформе абсолютно бесплатна, и вы можете начать торговать на учебном демо счете до того, как пополните свой счет и начнете зарабатывать настоящие деньги.

          Для того, чтобы торговать на реальном счете вам потребуется пройти Подтверждение личности (KYC) — это стандартный процесс идентификации клиентов биржи. Оно позволяет нам защищать данные наших пользователей. Торговля, обмен, пополнение и вывод активов на нашей платформе становятся доступными сразу после прохождения подтверждения личности. Вам также нужно принять Клиентское соглашение в форме регистрации, для того чтобы начать свою торговлю.
        `,
			},
			{
				title: 'Демо счет',
				content: ``,
			},
			{
				title: 'Минимальный депозит',
				content: ``,
			},
			{
				title: 'Ограничения по открытию счета',
				content: ``,
			},
		],
	},
	{
		title: 'Терминология',
		code: 'termin',
		items: [],
	},
	{
		title: 'Вывод средств со счета',
		code: 'payouts',
		items: [],
	},
	{
		title: 'Турниры',
		code: 'tournaments',
		items: [],
	},
	{
		title: 'Бонусы',
		code: 'bonuses',
		items: [],
	},
];

const activeCode = ref('');
const activeSection = computed(() =>
	sections.find(item => item.code === activeCode.value),
);
</script>

<template>
	<PageDrawersItem
		title="Обучение"
		:name="PAGE_DRAWERS.education"
	>
		<div
			v-show="!activeSection"
			class="flex flex-col gap-6 pt-4"
		>
			<button
				v-for="section in sections"
				:key="section.code"
				type="button"
				class="text-gray-400 hover:text-white leading-tight text-sm font-normal flex justify-between items-center pb-3 border-b border-gray-600"
				@click="activeCode = section.code"
			>
				<span>
					{{ section.title }}
				</span>

				<BaseIcon
					name="base--fill--chevron-right"
					class="text-[18px]"
				/>
			</button>
		</div>

		<div
			v-if="activeSection"
			class="flex flex-col"
		>
			<BaseAccordion
				v-for="item in activeSection.items"
				:key="item.title"
				:title="item.title"
				:text="item.content"
			/>
		</div>
	</PageDrawersItem>
</template>
