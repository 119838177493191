<script setup lang="ts">
import { pageDrawers } from '../core';
import type { PageDrawers } from '../core/types';
import { useOpenTerminalPage } from '~/src/shared/lib';
import { DrawersItem } from '~/src/modules/drawers';

defineProps<{
	name: PageDrawers;
	title: string;
	fullscreen?: boolean;
}>();

const openTerminalPage = useOpenTerminalPage();
const onClose = () => {
	pageDrawers.close();
	openTerminalPage();
};
const { $viewport } = useNuxtApp();
const isMobile = computed(() => $viewport.match('sm'));
</script>

<template>
	<DrawersItem
		:name="name"
		:title="title"
		:fullscreen="fullscreen || isMobile"
		:use-drawer-names="pageDrawers.useDrawerNames"
		@back="pageDrawers.back()"
		@close="onClose"
	>
		<slot />
	</DrawersItem>
</template>
